import React from 'react'
import styled, { createGlobalStyle } from 'styled-components'

import logoImage from '../images/wordmark.png'
import demoImage from '../images/demo.png'
import SeoCore from '../components/seo/core'

// import useFirebase from '../services/usefirebase'

const formLink = 'https://docs.google.com/forms/d/e/1FAIpQLSc8e1Tr7iwgYmUC1xS_f6gbywENJnmKrzlY9vq0okOQcjGSXw/viewform?usp=sf_link'

const Index = () => {
	// const firebase = useFirebase();
	
	// const handleSubmit = event => {
	// 	event.preventDefault()
	// 	const store = firebase.firestore()
	// 	const collection = store.collection('visitor_contact_details')

	// 	store.collection('visitor_contact_details')
	// 		.get()
	// 		.then((res) => {
	// 			debugger
	// 		})

	// 	collection.add({ email: 'found2' }).then(function (docRef) {
	// 		console.log(docRef)
	// 	})
	// 	alert('Welcome!')
	// }

	return (
		<RootStyles>
			<SeoCore />

			<GlobalStyles/>

			<GridStyles>
				<FirstPaneStyles>
					<LogoStyles src={logoImage} draggable={false} />

					<HeaderStyles>Product wireframes</HeaderStyles>
					<SubheaderStyles>Updated every week</SubheaderStyles>
					<ButtonStyles href={formLink} target='_blank'>LEARN MORE</ButtonStyles>	
				</FirstPaneStyles>

				<SecondPaneStyles>
					<DemoImageStyles src={demoImage} draggable={false} />
				</SecondPaneStyles>
			</GridStyles>
		</RootStyles>
	)
}

const RootStyles = styled.div`
	height: 100vh;
`

const GlobalStyles = createGlobalStyle`
  html {
    margin: 0;
	}
	body {
		padding: 0;
		margin: 0;
		color: #21211e;
    font-family: 'Helvetica Neue', Helvetica, 'Helvetica Neue', Helvetica;
	}

  ::selection {
    color: white;
    background-color: #3e3e3b;
  }
`

const GridStyles = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
	@media (min-width: 768px) {
    display: grid;
    grid-template-columns: 2fr 1.5fr;
    grid-template-rows: 1fr;
  }
`

const FirstPaneStyles = styled.div`
  flex: 1;
  padding: 4em;
	background-color: #ebeced;
`

const SecondPaneStyles = styled.div`
	display: none;
  background-color: #21211e;
	@media (min-width: 768px) {
		display: inherit;
	}
`

const LogoStyles = styled.img`
	height: 36px;
`

const HeaderStyles = styled.h1`
	font-weight: 400;
	font-size: 3em;
	margin-top: 1em;
`

const SubheaderStyles = styled.h2`
	font-weight: 400;
	font-size: 2em;
`

const ButtonStyles = styled.a`
	display: inline-block;
	font-size: 1.25em;
	margin-top: 2em;
	padding: 0.75em 2em;
	border: 0.25em solid #21211e;
	border-radius: 0.12em;
	box-sizing: border-box;
	text-decoration: none;
	font-weight: 600;
	color: #21211e;
	text-align:center;
	transition: all 0.2s;
  &:hover {
		color: #ffffff;
		background-color: #21211e;
	}
`

const DemoImageStyles = styled.img`
	max-width: 100%;
	max-height: 100vh;
`

export default Index	